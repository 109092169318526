import headers from './components/headers.vue'
import footers from './components/footers.vue'
import hero from './components/hero.vue'
import breadcrumb from './components/breadcrumb.vue'
import swiper from './components/swiper.vue'
import hotbot from './components/hotbot.vue'
import { createApp } from 'vue'

export default function partials(){
  return new Promise((resolve) => {
    const app = createApp({
      components: {
        headers: headers,
        footers: footers,
        hero: hero,
        breadcrumb: breadcrumb,
        swiper: swiper,
        hotbot: hotbot,
      }
    });
    
    // Vue.jsを#appにマウント
    app.mount('#app');
    resolve();
  });
}
