// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&family=Roboto:wght@300;400;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* BASE COLOR
------------------------------------------------------------*/
/* DISPLAY SETTING
------------------------------------------------------------*/
/* OTHER SETTING
------------------------------------------------------------*/
[data-v-7139e23c]:root {
  font-size: calc(14.5px + 1.5 * (100vw - 1440px) / 480);
  --cx: 0;
  --cy: 0;
  --vw: 100vw;
  --vh: 100vh;
  --screen: 0px;
  --size: 32px;
}
@media screen and (min-width: 1920px) {
[data-v-7139e23c]:root {
    font-size: 16px;
}
}
@media screen and (max-width: 1023px) {
[data-v-7139e23c]:root {
    font-size: 14px;
    --size: 16px;
}
}
@media screen and (max-width: 769px) {
[data-v-7139e23c]:root {
    font-size: 13.5px;
    --size: 10px;
}
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 100;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 200;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 300;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 400;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 500;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold");
  font-weight: bold;
}
footer[data-v-7139e23c] {
  background: #f4f4f7;
  padding: 0 0 4rem;
}
@media screen and (max-width: 769px) {
footer[data-v-7139e23c] {
    padding: 0 0 3.2rem;
}
}
footer a[data-v-7139e23c] {
  color: #565656;
}
footer .pagetop[data-v-7139e23c] {
  background: #f4f4f7;
  display: block;
  text-align: center;
  padding: 1rem 0;
}
footer nav[data-v-7139e23c] {
  padding: 3.2rem calc(var(--size) + 2vw);
  border-top: #fcfcff 0.2rem solid;
}
@media screen and (max-width: 769px) {
footer nav[data-v-7139e23c] {
    padding: 3.2rem calc(calc(var(--size) + 2vw) * 1.5);
}
}
footer nav ul[data-v-7139e23c] {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 769px) {
footer nav ul[data-v-7139e23c] {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 0.2rem;
    grid-row-gap: 0.2rem;
    border-radius: 0.4rem;
    overflow: hidden;
}
}
footer nav ul li[data-v-7139e23c] {
  margin: 0 0 0 3.2rem;
}
@media screen and (max-width: 769px) {
footer nav ul li[data-v-7139e23c] {
    margin: 0;
}
}
footer nav ul li[data-v-7139e23c]:first-child {
  margin: 0;
}
@media screen and (max-width: 769px) {
footer nav ul li a[data-v-7139e23c] {
    background: #fcfcff;
    display: block;
    padding: 0.8rem 1.6rem;
}
}
footer nav ul li a[data-v-7139e23c]:hover {
  color: #f8765c;
  text-decoration: underline;
}
footer small[data-v-7139e23c] {
  display: block;
  text-align: center;
  font-size: 0.72rem;
}`, "",{"version":3,"sources":["webpack://./src/scss/module/_module.scss","webpack://./footers.vue","webpack://./src/js/components/footers.vue"],"names":[],"mappings":"AAEA;6DAAA;AAaA;6DAAA;AAOA;6DAAA;AAEA;EACC,sDAAA;EACA,OAAA;EACA,OAAA;EACA,WAAA;EACA,WAAA;EACC,aAAA;EACA,YAAA;AChBF;ADiBC;AARD;IASE,eAAA;ACdA;AACF;ADeC;AAXD;IAYE,eAAA;IACE,YAAA;ACZF;AACF;ADaC;AAfD;IAgBE,iBAAA;IACE,YAAA;ACVF;AACF;ADiEA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;AC9DD;ADgEA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;AC9DD;ADgEA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;AC9DD;ADgEA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;AC9DD;ADgEA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;AC9DD;ADgEA;EACC,wBAAA;EACA,4BAAA;EACA,iBAAA;AC9DD;AC7DA;EACE,mBFMK;EELL,iBAAA;AD+DF;AC9DE;AAHF;IAII,mBAAA;ADiEF;AACF;AChEE;EACE,cFLK;ACuET;AChEE;EACE,mBFHG;EEIH,cAAA;EACA,kBAAA;EACA,eAAA;ADkEJ;AChEE;EACE,uCAAA;EACA,gCAAA;ADkEJ;ACjEI;AAHF;IAII,mDAAA;ADoEJ;AACF;ACnEI;EFuBH,aAAA;EACA,mBAAA;EACA,8BAHiC;EAIhC,iBAAA;EExBI,mBAAA;EACA,uBAAA;ADwEN;ACvEM;AAJF;IAKI,aAAA;IACA,0BAAA;IACA,uBAAA;IACA,oBAAA;IACA,qBAAA;IACA,gBAAA;AD0EN;AACF;ACzEM;EACE,oBAAA;AD2ER;AC1EQ;AAFF;IAGI,SAAA;AD6ER;AACF;AC5EQ;EACE,SAAA;AD8EV;AC3EU;AADF;IAEI,mBFrCJ;IEsCI,cAAA;IACA,sBAAA;AD8EV;AACF;AC7EU;EACE,cF7CL;EE8CK,0BAAA;AD+EZ;ACzEE;EACE,cAAA;EACA,kBAAA;EACA,kBAAA;AD2EJ","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
