// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&family=Roboto:wght@300;400;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* BASE COLOR
------------------------------------------------------------*/
/* DISPLAY SETTING
------------------------------------------------------------*/
/* OTHER SETTING
------------------------------------------------------------*/
[data-v-7653fc8a]:root {
  font-size: calc(14.5px + 1.5 * (100vw - 1440px) / 480);
  --cx: 0;
  --cy: 0;
  --vw: 100vw;
  --vh: 100vh;
  --screen: 0px;
  --size: 32px;
}
@media screen and (min-width: 1920px) {
[data-v-7653fc8a]:root {
    font-size: 16px;
}
}
@media screen and (max-width: 1023px) {
[data-v-7653fc8a]:root {
    font-size: 14px;
    --size: 16px;
}
}
@media screen and (max-width: 769px) {
[data-v-7653fc8a]:root {
    font-size: 13.5px;
    --size: 10px;
}
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 100;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 200;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 300;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 400;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 500;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold");
  font-weight: bold;
}
header[data-v-7653fc8a] {
  width: 100%;
  position: fixed;
  padding: 0.8rem calc(var(--size) + 2vw);
  background: #fcfcff;
  border-bottom: #e8e8eb 1px solid;
  display: grid;
  grid-template-columns: auto auto;
  z-index: 1000;
}
@media screen and (max-width: 769px) {
header[data-v-7653fc8a] {
    padding: 0.72rem calc(var(--size) + 2vw);
}
}
header[data-v-7653fc8a] > * {
  align-self: center;
}
header .logo[data-v-7653fc8a] {
  width: 11.6rem;
  justify-self: flex-start;
}
@media screen and (max-width: 769px) {
header .logo[data-v-7653fc8a] {
    width: 11rem;
}
}
header .logo img[data-v-7653fc8a] {
  width: 100%;
}
header nav[data-v-7653fc8a] {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 1023px) {
header nav[data-v-7653fc8a] {
    display: none;
}
}
header nav ul[data-v-7653fc8a] {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
header nav ul li[data-v-7653fc8a] {
  margin: 0 0 0 3.2rem;
}
header nav ul li a[data-v-7653fc8a] {
  color: #565656;
}
header nav ul li a[data-v-7653fc8a]:hover {
  color: #f8765c;
}
header nav ul li[data-v-7653fc8a]:first-child {
  margin: 0;
}
header .btn[data-v-7653fc8a] {
  justify-self: flex-end;
  color: #fcfcff;
  border: #f8765c 1px solid;
  font-size: 1.08rem;
  line-height: 1.5;
  padding: 0.8rem 3.2rem;
  border-radius: 999rem;
  overflow: hidden;
  position: relative;
  display: inline-block;
  z-index: 1;
  transition: color 320ms cubic-bezier(0.65, 0, 0.35, 1);
}
header .btn[data-v-7653fc8a]:before {
  content: "";
  background: #f8765c;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: width 320ms cubic-bezier(0.65, 0, 0.35, 1);
}
header .btn[data-v-7653fc8a]:hover {
  color: #f8765c;
}
header .btn[data-v-7653fc8a]:hover:before {
  left: auto;
  right: 0;
  width: 0;
}
@media screen and (max-width: 1023px) {
header .btn[data-v-7653fc8a] {
    color: #fcfcff;
    border: #f8765c 1px solid;
    font-size: 1.08rem;
    line-height: 1.5;
    padding: 0.64rem 1.6rem;
    border-radius: 999rem;
    overflow: hidden;
    position: relative;
    display: inline-block;
    z-index: 1;
    transition: color 320ms cubic-bezier(0.65, 0, 0.35, 1);
}
header .btn[data-v-7653fc8a]:before {
    content: "";
    background: #f8765c;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: width 320ms cubic-bezier(0.65, 0, 0.35, 1);
}
header .btn[data-v-7653fc8a]:hover {
    color: #f8765c;
}
header .btn[data-v-7653fc8a]:hover:before {
    left: auto;
    right: 0;
    width: 0;
}
}`, "",{"version":3,"sources":["webpack://./src/scss/module/_module.scss","webpack://./headers.vue","webpack://./src/js/components/headers.vue"],"names":[],"mappings":"AAEA;6DAAA;AAaA;6DAAA;AAOA;6DAAA;AAEA;EACC,sDAAA;EACA,OAAA;EACA,OAAA;EACA,WAAA;EACA,WAAA;EACC,aAAA;EACA,YAAA;AChBF;ADiBC;AARD;IASE,eAAA;ACdA;AACF;ADeC;AAXD;IAYE,eAAA;IACE,YAAA;ACZF;AACF;ADaC;AAfD;IAgBE,iBAAA;IACE,YAAA;ACVF;AACF;ADiEA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;AC9DD;ADgEA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;AC9DD;ADgEA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;AC9DD;ADgEA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;AC9DD;ADgEA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;AC9DD;ADgEA;EACC,wBAAA;EACA,4BAAA;EACA,iBAAA;AC9DD;AC7DA;EACE,WAAA;EACA,eAAA;EACA,uCAAA;EACA,mBFEM;EEDN,gCAAA;EACA,aAAA;EACA,gCAAA;EACA,aAAA;AD+DF;AC9DE;AATF;IAUI,wCAAA;ADiEF;AACF;AChEE;EACE,kBAAA;ADkEJ;AChEE;EACE,cAAA;EACA,wBAAA;ADkEJ;ACjEI;AAHF;IAII,YAAA;ADoEJ;AACF;ACnEI;EACE,WAAA;ADqEN;AClEE;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;ADoEJ;ACnEI;AALF;IAMI,aAAA;ADsEJ;AACF;ACrEI;EFWH,aAAA;EACA,mBAAA;EACA,8BAHiC;EAIhC,iBAAA;EEZI,uBAAA;EACA,mBAAA;AD0EN;ACzEM;EACE,oBAAA;AD2ER;AC1EQ;EACE,cFtCD;ACkHT;AC3EU;EACE,cFvCL;ACoHP;AC1EQ;EACE,SAAA;AD4EV;ACvEE;EACE,sBAAA;EFWF,cAzDM;EA0DN,yBAAA;EACA,kBAAA;EACA,gBAAA;EACA,sBALiD;EAMjD,qBAAA;EACA,gBAAA;EACA,kBAAA;EACA,qBAAA;EACA,UAAA;EACA,sDAAA;AC+DF;AD9DE;EACE,WAAA;EACA,mBAzEG;EA0EH,WAAA;EACA,YAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,sDAAA;ACgEJ;AD9DE;EACE,cAnFG;ACmJP;AD/DI;EACE,UAAA;EACA,QAAA;EACA,QAAA;ACiEN;ACrGI;AAHF;IFYA,cAzDM;IA0DN,yBAAA;IACA,kBAAA;IACA,gBAAA;IACA,uBEZgC;IFahC,qBAAA;IACA,gBAAA;IACA,kBAAA;IACA,qBAAA;IACA,UAAA;IACA,sDAAA;ACgGA;AD/FA;IACE,WAAA;IACA,mBAzEG;IA0EH,WAAA;IACA,YAAA;IACA,kBAAA;IACA,MAAA;IACA,OAAA;IACA,WAAA;IACA,sDAAA;ACiGF;AD/FA;IACE,cAnFG;ACoLL;ADhGE;IACE,UAAA;IACA,QAAA;IACA,QAAA;ACkGJ;AACF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
