<template>
  <header id="site-header">
    <figure class="logo">
      <a href="/#app">
        <img src="/images/common/logo.webp" alt="hotbot ui" />
      </a>
    </figure>
    <nav class="gnavi" role="navigation" itemscope="itemscope" itemtype="http://scheme.org/SiteNavigationElement">
      <ul>
        <li itemprop="name"><a itemprop="url" href="#overview">Overview</a></li>
        <li itemprop="name"><a itemprop="url" href="#wordpress">Wordpress Plugin</a></li>
        <li itemprop="name"><a itemprop="url" href="#static">Use Static</a></li>
        <li itemprop="name"><a itemprop="url" href="#qa">Q&A</a></li>
        <li itemprop="name"><a itemprop="url" href="#license">License</a></li>
      </ul>
    </nav>
    <a href="/dist/hotbot-ui.zip" class="btn">Download</a>
  </header>
</template>

<style lang="scss" scoped>
header{
  width: 100%;
  position: fixed;
  padding: .8rem side();
  background: $white;
  border-bottom: $border 1px solid;
  display: grid;
  grid-template-columns: auto auto;
  z-index: 1000;
  @media screen and (max-width: $phone){
    padding: .72rem side();
  }
  > *{
    align-self: center;
  }
  .logo{
    width: 11.6rem;
    justify-self: flex-start;
    @media screen and (max-width: $phone){
      width: 11rem;
    }
    img{
      width: 100%;
    }
  }
  nav{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media screen and (max-width: $tab){
      display: none;
    }
    ul{
      @include flex();
      justify-content: center;
      align-items: center;
      li{
        margin: 0 0 0 3.2rem;
        a{
          color: $normal;
          &:hover{
            color: $base;
          }
        }
        &:first-child{
          margin: 0;
        }
      }
    }
  }
  .btn{
    justify-self: flex-end;
    @include btn($white, $base);
    @media screen and (max-width: $tab){
      @include btn($white, $base, .64rem 1.6rem);
    }
  }
}
</style>

<script>
export default {
}
</script>
