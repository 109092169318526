<template>
	<footer id="site-footer" class="in-module" role="contentinfo" itemscope="itemscope" itemtype="http://schema.org/WPFooter">
    <a href="#app" class="pagetop">▲ pagetop</a>
    <div class="max">
      <nav>
        <ul>
          <li itemprop="name"><a itemprop="url" href="#overview">Overview</a></li>
          <li itemprop="name"><a itemprop="url" href="#wordpress">Wordpress Plugin</a></li>
          <li itemprop="name"><a itemprop="url" href="#static">Use Static</a></li>
          <li itemprop="name"><a itemprop="url" href="#qa">Q&A</a></li>
          <li itemprop="name"><a itemprop="url" href="#license">License</a></li>
        </ul>
      </nav>
      <small class="copy">Copyright &copy; {{ currentYear }} hotbot-ui All rights reserved.</small>
    </div>
	</footer>
</template>

<style lang="scss" scoped>
footer{
  background: $gray;
  padding: 0 0 4rem;
  @media screen and (max-width: $phone){
    padding: 0 0 3.2rem;
  }
  a{
    color: $normal;
  }
  .pagetop{
    background: $gray;
    display: block;
    text-align: center;
    padding: 1rem 0;
  }
  nav{
    padding: 3.2rem side();
    border-top: $white .2rem solid;
    @media screen and (max-width: $phone){
      padding: 3.2rem side15x();
    }
    ul{
      @include flex();
      align-items: center;
      justify-content: center;
      @media screen and (max-width: $phone){
        display: grid;
        grid-template-columns: 1fr;
        grid-column-gap: .2rem;
        grid-row-gap: .2rem;
        border-radius: .4rem;
        overflow: hidden;
      }
      li{
        margin: 0 0 0 3.2rem;
        @media screen and (max-width: $phone){
          margin: 0;
        }
        &:first-child{
          margin: 0;
        }
        a{
          @media screen and (max-width: $phone){
            background: $white;
            display: block;
            padding: .8rem 1.6rem;
          }
          &:hover{
            color: $base;
            text-decoration: underline;
          }
        }
      }
    }
  }
  small{
    display: block;
    text-align: center;
    font-size: .72rem;
  }
}
</style>

<script>
export default {
	data() {
		return {
			currentYear: new Date().getFullYear()
		}
	}
}
</script>