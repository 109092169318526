<template>
  <div id="hotbot" :style="styleVars">
    <Transition name="hotbot">
      <a id="chatopen" v-if="!view" @click="view = true">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="M260-410h280v-40H260v40Zm0-120h440v-40H260v40Zm0-120h440v-40H260v40Zm220 530-91.62-140H184.62q-26.66 0-45.64-18.98T120-324.62v-450.76q0-26.66 18.98-45.64T184.62-840h590.76q26.66 0 45.64 18.98T840-775.38v450.76q0 26.66-18.98 45.64T775.38-260H571.62L480-120Zm0-72.46L550.15-300h225.23q10.77 0 17.7-6.92 6.92-6.93 6.92-17.7v-450.76q0-10.77-6.92-17.7-6.93-6.92-17.7-6.92H184.62q-10.77 0-17.7 6.92-6.92 6.93-6.92 17.7v450.76q0 10.77 6.92 17.7 6.93 6.92 17.7 6.92h225.23L480-192.46ZM480-550Z"/></svg>
      </a>
    </Transition>
    <Transition name="hotbot">
      <div class="botwrapeer" v-if="view">
        <a class="botwrapeer__close" @click="view = false"></a>
        <h4 class="botwrapeer__title"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="M260-410h280v-40H260v40Zm0-120h440v-40H260v40Zm0-120h440v-40H260v40Zm220 530-91.62-140H184.62q-26.66 0-45.64-18.98T120-324.62v-450.76q0-26.66 18.98-45.64T184.62-840h590.76q26.66 0 45.64 18.98T840-775.38v450.76q0 26.66-18.98 45.64T775.38-260H571.62L480-120Zm0-72.46L550.15-300h225.23q10.77 0 17.7-6.92 6.92-6.93 6.92-17.7v-450.76q0-10.77-6.92-17.7-6.93-6.92-17.7-6.92H184.62q-10.77 0-17.7 6.92-6.92 6.93-6.92 17.7v450.76q0 10.77 6.92 17.7 6.93 6.92 17.7 6.92h225.23L480-192.46ZM480-550Z"/></svg>{{ title }}</h4>
        <div class="botwrapeer__box" id="chatlist">
          <ul class="botwrapeer__box__list">
            <li class="user" v-for="item in box" :key="item.count" :id="'chatID' + item.count" :class="item.class" :data-date="item.date">
              <img v-if="item.class == 'owner'" :src="this.ownerAvatar" alt="owner">
              <img v-else :src="this.userAvatar" alt="user">

              <p v-html="item.txt"></p>
              <a v-if="item.link" :href="item.link" target="_blank" rel="nofollow">Link<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="M224.62-160q-27.62 0-46.12-18.5Q160-197 160-224.62v-510.76q0-27.62 18.5-46.12Q197-800 224.62-800h224.61v40H224.62q-9.24 0-16.93 7.69-7.69 7.69-7.69 16.93v510.76q0 9.24 7.69 16.93 7.69 7.69 16.93 7.69h510.76q9.24 0 16.93-7.69 7.69-7.69 7.69-16.93v-224.61h40v224.61q0 27.62-18.5 46.12Q763-160 735.38-160H224.62Zm164.92-201.23-28.31-28.31L731.69-760H560v-40h240v240h-40v-171.69L389.54-361.23Z"/></svg></a>
            </li>
          </ul>
        </div>
        <form class="botwrapeer__input" v-on:submit.prevent="comment()">
          <input type="text" class="botwrapeer__input__area" placeholder="質問を入力" v-model="message">
          <div class="botwrapeer__input__trg" @click="comment()">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="M160-220v-206.15L393.85-480 160-533.85V-740l616.92 260L160-220Z"/></svg>
          </div>
        </form>
      </div>
    </Transition>
  </div>
</template>

<style lang="scss" scoped>
.hotbot-enter-active, .hotbot-leave-active {
	transition: 400ms;
}
.hotbot-enter, .hotbot-leave-to {
	opacity: 0;
	transform: scale(1.04);
}
#hotbot{
	position: fixed;
	right: 16px;
	bottom: 16px;
	z-index: 2000;
  *{
    box-sizing: border-box;
  }
  html,body,div,span,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,abbr,address,cite,code,del,dfn,em,img,ins,kbd,q,samp,small,strong,sub,sup,var,b,i,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,figcaption,figure,footer,header,hgroup,menu,nav,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;outline:0;font-size:100%;vertical-align:baseline;background:transparent}
  article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}
  nav ul{list-style:none}
  blockquote,q{quotes:none}
  blockquote:before,blockquote:after,q:before,q:after{content:none}
  a{margin:0;padding:0;font-size:100%;vertical-align:baseline;background:transparent}
  ins{background-color:#ff9;color:#000;text-decoration:none}
  mark{background-color:#ff9;color:#000;font-style:italic;font-weight:bold}
  del{text-decoration:line-through}
  abbr[title],dfn[title]{border-bottom:1px dotted;cursor:help}
  table{border-collapse:collapse;border-spacing:0}
  hr{display:block;height:1px;border:0;border-top:1px solid #ccc;margin:1em 0;padding:0}
  input,select{vertical-align:middle}
  main{display: block;}
	#chatopen{
		width: 64px;
		height: 64px;
		background: #FFF;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		transition: transform 200ms var(--ease);
		box-shadow: 0 0 32px rgba(#000,.16);
		position: absolute;
		right: 0;
		bottom: 0;
    cursor: pointer;
    svg{
      width: 32px;
      height: 32px;
      fill: var(--font-color);
      opacity: .8;
    }
		&:hover{
			transform: scale(1.1);
		}
	}
	.botwrapeer{
    font-size: 13.5px;
		width: 350px;
    max-width: calc(100vw - 32px);
		height: 500px;
    min-height: 50dvh;
    max-height: calc(100vw - 32px);
		background: var(--background-color);
		border-radius: 6px;
		border: var(--base-color) 3px solid;
		box-shadow: 0 0 32px rgba(#000,.24);
		position: absolute;
		right: 0;
		bottom: 0;
		&__close{
			position: absolute;
			z-index: 10;
			top: 11px;
			right: 10px;
			width: calc(var(--title-size) * .5);
			height: calc(var(--title-size) * .5);
			cursor: pointer;
			&:before,
			&:after{
				content: '';
				width: 100%;
				height: 1px;
				background: var(--title-color);
				position: absolute;
				top: 50%;
				left: 0;
				transform: rotate(45deg);
				transition: transform 160ms var(--ease);
			}
			&:after{
				transform: rotate(-45deg);
			}
			&:hover{
				&:before,
				&:after{
					transform: rotate(0deg);
				}
			}
		}
		&__title{
			background: var(--base-color);
			font-size: 16px;
			line-height: 1;
			font-weight: 400;
			height: var(--title-size);
			display: flex;
			align-items: center;
			justify-content: flex-start;
			color: var(--title-color);
			padding: 0 16px;
			svg{
        width: 24px;
        height: 24px;
				display: inline-block;
				margin: 0 8px 0 0;
				fill: var(--title-color);
				transform: rotateY(180deg);
			}
		}
		&__box{
			$margin: 16px;
			position: absolute;
			top: var(--title-size);
			left: $margin;
			height: calc(100% - var(--title-size) - var(--input-size));
			width: calc(100% - #{$margin});
			overflow: auto;
			&__list{
				li{
					list-style: none;
					background: #FFF;
					border-radius: 12px 12px 12px 0;
					padding: 10px 14px;
          line-height: 1.56;
					margin: 0 0 40px;
					margin-left: calc(var(--avatar-size) + 8px);
					position: relative;
					clear: both;
          opacity: 0;
          word-wrap: break-word;
					width: calc(84% - var(--avatar-size));
          animation: showBotAnimation 400ms var(--ease) forwards;
          @keyframes showBotAnimation {
            0%{
              opacity: 0;
              transform: translateY(1rem);
            }
            100%{
              opacity: 1;
              transform: translateY(0);
            }
          }
					&:first-child{
						margin-top: $margin;
					}
					&:after{
						content: attr(data-date);
						font-size: 9px;
						opacity: .64;
						position: absolute;
						left: calc(-1 * var(--avatar-size) - 8px);
						bottom: -24px;
					}
					img{
						content: '';
						width: var(--avatar-size);
						height: var(--avatar-size);
            object-fit: cover;
						border-radius: 50%;
						position: absolute;
						left: calc(-1 * var(--avatar-size) - 8px);
						bottom: 0;
					}
					&.owner{
						background: var(--message-color);
						float: right;
						border-radius: 12px 12px 0 12px;
						margin-right: calc(var(--avatar-size) + $margin + 8px);
						a{
							color: var(--font-color);
							position: relative;
							background: #FFF;
							padding: 4px 12px;
							display: block;
							border-radius: 6px;
							margin: 8px 0 0;
							svg{
								width: 18px;
                height: 18px;
                fill: var(--font-color);
								position: absolute;
								top: 50%;
								right: 6px;
								transform: translateY(-50%);
							}
						}
						img{
							left: auto;
							right:  calc(-1 * var(--avatar-size) - 8px);
						}
						&:after{
							left: auto;
							right: calc(-1 * var(--avatar-size) - 8px);
						}
					}
				}
			}
		}
		&__input{
			background: var(--background-color);
			border-radius: 0 0 5px 5px;
			overflow: hidden;
			width: 100%;
			height: var(--input-size);
			position: absolute;
			left: 0;
			bottom: 0;
			z-index: 10;
			&__area{
				display: flex;
				font-size: 15px;
				position: absolute;
				bottom: 0;
				left: 0;
				background: #FFF;
				border: none;
				height: var(--input-size);
				max-height: var(--input-size);
				min-height: var(--input-size);
				line-height: var(--input-size);
				padding: 8px 15px;
				border-radius: 0 0 6px 6px;
				outline: none;
        -webkit-appearance: none;
        appearance: none;
				width: calc(100% - var(--input-size));
				min-width: calc(100% - var(--input-size));
				max-width: calc(100% - var(--input-size));
				z-index: 1;
			}
			&__trg{
				width: var(--input-size);
				height: var(--input-size);
        border-left: var(--background-color) 1px solid;
				position: absolute;
				top: 0;
				right: 0;
				background: #FFF;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
				z-index: 2;
				&:hover{
					i{
						opacity: 1;
					}
				}
				&:before{
					content: '';
					width: 1px;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					background: rgba(var(--base-color),.1);
				}
				svg{
          width: 20px;
          height: 20px;
					fill: var(--font-color);
					opacity: .32;
				}
			}
		}
	}
}
</style>

<script>
export default {
  data: function () {
    return {
      view: false,
      message: '',
      box: [{
        txt: this.firstMessage,
        class: 'owner',
        count: 0,
        link: false,
        date: '最初のメッセージ'
      }],
      error: this.errorMessage,
      res: [],
      count: 1,
      load: false,
    }
  },
  methods: {
    comment: function(){
      if(this.message != '' && !this.load){
        this.count++
        this.box.push({
          txt: this.esc(this.message),
          class: 'user',
          count: this.count,
          date: this.getdate(),
        })

        if(this.useStatic){
          this.staticfunc(this.esc(this.message))
        }else{
          this.answer(this.esc(this.message))
        }
        this.message = ''
        this.load = true
      }
    },
    answer: function(str){
      fetch(this.json + str)
        .then(response => response.json())
        .then(data => {
          if(data.message){
            this.callback(this.error)
          }else{
            this.callback(data.answers, data.links)
          }
        })
        .catch(error => {
          this.callback(this.error)
        })
    },

    
    staticfunc(str) {
      fetch(this.json)
        .then(response => response.json())
        .then(data => {
          let matchedObject = null;
          let maxMatches = 0;
          const words = str.split(/\s+/);

          data.forEach((obj) => {
            let matchCount = 0;
            obj.keywords.forEach(keyword => {
              words.forEach(word => {
                if (word.includes(keyword) || keyword.includes(word)) {
                  matchCount++;
                }
              });
            });
            if (matchCount > maxMatches) {
              maxMatches = matchCount;
              matchedObject = obj;
            }
          });

          if (matchedObject && maxMatches > 0) {
            this.callback(matchedObject.answer, matchedObject.link);
          } else {
            this.callback(this.error);
          }
        })
        .catch(error => {
          this.callback(this.error);
        });
    },

    callback: function(result, link = false){
      this.count++
      this.box.push({
        txt: result,
        link: link,
        class: 'owner',
        count: this.count,
        date: this.getdate(),
      })

      this.load = false
      let elem = document.getElementById('chatlist')
      if (elem) {
        setTimeout(function(){
          elem.scrollTop = elem.scrollHeight
        }, 100)
      }
    },
    esc: function(str){
      return str.replace(/&/g,'&amp;').replace(/"/g,'&quot;').replace(/'/g,'&#039;').replace(/</g,'&lt;').replace(/>/g,'&gt;')
    },
    getdate: function(){
      const date = new Date().toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' })
      return date
    }
  },
  computed: {
    styleVars() {
      return {
        '--base-color': this.baseColor,
        '--font-color': this.fontColor,
        '--message-color': this.messageColor,
        '--title-color': this.titleColor,
        '--background-color': this.backgroundColor,
        '--ease': this.ease,
        '--title-size': this.titleSize,
        '--input-size': this.inputSize,
        '--avatar-size': this.avatarSize,
      }
    }
  },
  props: {
    json: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: 'hotbot-ui'
    },
    firstMessage: {
      type: String,
      default: 'First Message'
    },
    errorMessage: {
      type: String,
      default: 'Error Message'
    },
    ownerAvatar: {
      type: String,
      default: '/images/hotbot/owner.png'
    },
    userAvatar: {
      type: String,
      default: '/images/hotbot/user.png'
    },
    baseColor: {
      type: String,
      default: '#000'
    },
    fontColor: {
      type: String,
      default: '#080808'
    },
    messageColor: {
      type: String,
      default: '#e0e0e0'
    },
    titleColor: {
      type: String,
      default: '#f2f2f2'
    },
    backgroundColor: {
      type: String,
      default: '#f5f5f5'
    },
    ease: {
      type: String,
      default: 'cubic-bezier(0.76, 0, 0.24, 1)'
    },
    titleSize: {
      type: String,
      default: '48px'
    },
    inputSize: {
      type: String,
      default: '42px'
    },
    avatarSize: {
      type: String,
      default: '32px'
    },
    useStatic: {
      type: Boolean,
      default: false
    },
  }
}
</script>